import { ProductDto } from "../../dto/Product.dto";

import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";

import {
  Footer,
  Name,
  Price,
  ProductCardContainer,
} from "./product-card.styles";

import { useDispatch, useSelector } from "react-redux";

import { addItemToCart } from "../../store/cart/cart.action";

import { selectCartItems } from "../../store/cart/cart.selector";

const ProductCard = ({ product }: { product: ProductDto }) => {
  const { name, price, imageUrl } = product;

  const dispatch = useDispatch();

  const cartItems = useSelector(selectCartItems);

  const addProductToCart = () => dispatch(addItemToCart(cartItems, product));

  return (
    <ProductCardContainer>
      <img src={imageUrl} alt={`${name}`} />
      <Footer>
        <Name>{name}</Name>
        <Price>$ {price}</Price>
      </Footer>

      <Button
        type="button"
        onClick={addProductToCart}
        buttonType={BUTTON_TYPE_CLASSES.inverted}
      >
        Add To Cart
      </Button>
    </ProductCardContainer>
  );
};
export default ProductCard;
