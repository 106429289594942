import { ChangeEventHandler } from "react";
import { FormInputLabel, Group, Input } from "./form-input.styles";

const FormInput = ({
  label,
  htmlFor,
  ...otherProps
}: {
  label: string;
  htmlFor: string;
  required: boolean;
  name: string;
  id: string;
  type: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <Group>
      <Input {...otherProps} />
      {label && (
        <FormInputLabel shrink={otherProps.value.length}>
          {label}
        </FormInputLabel>
      )}
    </Group>
  );
};
export default FormInput;
