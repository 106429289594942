import { useState } from "react";

import FormInput from "../form-input/form-input.component";

import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import { SignUpFormContainer } from "./sign-up-form.styles";
import { useDispatch } from "react-redux";
import { signUpStart } from "../../store/user/user.action";

const defaultFormFields = {
  displayName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUpForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, email, password, confirmPassword } = formFields;

  const dispatch = useDispatch();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    try {
      dispatch(signUpStart(email, password, displayName));
      resetFormFields();
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        alert("This email is already registered!");
      }
      console.log("User creation encountered an error: ", error);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <SignUpFormContainer>
      <h2>Don't have an account?</h2>
      <span>Sigh up with your email and password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Display Name"
          htmlFor="displayName"
          required
          name="displayName"
          id="displayName"
          type="text"
          value={displayName}
          onChange={handleChange}
        />

        <FormInput
          label="Email"
          htmlFor="email"
          required
          type="email"
          id="email"
          onChange={handleChange}
          value={email}
          name="email"
        />

        <FormInput
          label="Password"
          htmlFor="password"
          required
          type="password"
          id="password"
          onChange={handleChange}
          value={password}
          name="password"
        />

        <FormInput
          label="Confirm Password"
          htmlFor="confirmPassword"
          required
          type="password"
          id="confirmPassword"
          onChange={handleChange}
          value={confirmPassword}
          name="confirmPassword"
        />

        <Button buttonType={BUTTON_TYPE_CLASSES.base} type="submit">
          Sign Up
        </Button>
      </form>
    </SignUpFormContainer>
  );
};
export default SignUpForm;
