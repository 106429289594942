import { CART_ACTION_TYPES } from "./cart.types";

import { createAction } from "../../utils/reducers/reducers.utils";
import { CartItemDto } from "../../dto/CartItem.dto";

const addCartItem = (cartItems: CartItemDto[], productToAdd: CartItemDto) => {
  let found: boolean = false;
  cartItems.map((cartItem) => {
    if (cartItem.id === productToAdd.id && cartItem.quantity) {
      cartItem.quantity += 1;
      found = true;
    }
    return cartItem;
  });

  if (!found) {
    return [...cartItems, { ...productToAdd, quantity: 1 }];
  } else {
    return [...cartItems];
  }
};

const removeCartItem = (
  cartItems: CartItemDto[],
  cartItemToRemove: CartItemDto
) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === cartItemToRemove.id
  );

  if (existingCartItem?.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== existingCartItem.id);
  }

  return cartItems.map((cartItem) =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity! - 1 }
      : cartItem
  );
};

const clearCartItem = (
  cartItems: CartItemDto[],
  cartItemToClear: CartItemDto
) => cartItems.filter((cartItem) => cartItem.id !== cartItemToClear.id);

export const setCartItems = (cartItems: any) =>
  createAction(CART_ACTION_TYPES.SET_CART_ITEMS, cartItems);

export const setIsCartOpen = (isCartOpen: boolean) =>
  createAction(CART_ACTION_TYPES.SET_IS_CART_OPEN, isCartOpen);

export const addItemToCart = (
  cartItems: CartItemDto[],
  productToAdd: CartItemDto
) => {
  const newCartItems = addCartItem(cartItems, productToAdd);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
};

export const removeItemFromCart = (
  cartItems: CartItemDto[],
  cartItemToRemove: CartItemDto
) => {
  const newCartItems = removeCartItem(cartItems, cartItemToRemove);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
};

export const clearItemFromCart = (
  cartItems: CartItemDto[],
  cartItemToClear: CartItemDto
) => {
  const newCartItems = clearCartItem(cartItems, cartItemToClear);
  return createAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
};
