import { initializeApp } from "firebase/app";

import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  User,
  UserCredential,
  signOut,
  onAuthStateChanged,
  NextOrObserver,
} from "firebase/auth";

import {
  getFirestore,
  getDoc,
  setDoc,
  doc,
  DocumentData,
  collection,
  writeBatch,
  query,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBDbadJh4BY5-7vE0tIdc45-ZpMIz6xYB4",
  authDomain: "mart-cart-db.firebaseapp.com",
  projectId: "mart-cart-db",
  storageBucket: "mart-cart-db.appspot.com",
  messagingSenderId: "49015257846",
  appId: "1:49015257846:web:032877ef360a190941cad7",
};

initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const db = getFirestore();

export const addCollectionAndDocuments = async (
  collectionKey: string,
  objectsToAdd: any
) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object: { title: string }) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });
  await batch.commit();
  console.log("done");
};

export const getCategoriesAndDocuments = async () => {
  const collectionKey = "categories";
  const collectionRef = collection(db, collectionKey);
  const categoriesQuery = query(collectionRef);

  const querySnapshot = await getDocs(categoriesQuery);
  return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
};

export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation = {}
) => {
  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapShot = await getDoc(userDocRef);

  if (!userSnapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation,
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }
  return userSnapShot;
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<UserCredential | null> => {
  if (!email || !password) return null;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInUserWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<DocumentData | null> => {
  if (!email || !password) {
    return null;
  }

  const { user } = await signInWithEmailAndPassword(auth, email, password);
  if (user) {
    return user;
  } else {
    alert("No User Found!");
  }

  return null;
};

export const signOutCurrentUser = async () => {
  const signOutResp = await signOut(auth)
    .then(() => {
      console.log("Signed Out Successfully!");
    })
    .catch((error) => {
      console.log(
        `An error occured while signing you out. Here is the message received: 
        ${error.message}`
      );
    });
  return signOutResp;
};

export const onAuthStateChangeListener = (callback: NextOrObserver<User>) => {
  onAuthStateChanged(auth, callback);
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};
