import styled from "styled-components";

export const ProductCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  @media (max-width: 920px) {
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 95%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none;
    @media (max-width: 600px) {
      opacity: 1;
      display: flex;
    }
  }

  &:hover {
    img {
      opacity: 0.8;
      @media (max-width: 600px) {
        opacity: 1;
      }
    }

    button {
      opacity: 0.85;
      display: flex;
      @media (max-width: 600px) {
        opacity: 1;
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`;

export const Name = styled.span`
  margin-bottom: 15px;
`;

export const Price = styled.span``;
