import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductCard from "../../components/product-card/product-card.component";
import { CategoryContainer, CategoryTitle } from "./category.styles";

import {
  selectCategoriesIsLoading,
  selectCategoriesMap,
} from "../../store/categories/category.selector";
import { useSelector } from "react-redux";
import { ProductDto } from "../../dto/Product.dto";
import Spinner from "../../components/spinner/spinner.component";

const Category = () => {
  const { category = "hats" } = useParams();

  const categoriesMap = useSelector(selectCategoriesMap);

  const isLoading = useSelector(selectCategoriesIsLoading);

  const [products, setProducts] = useState(categoriesMap[category]);

  useEffect(() => {
    setProducts(categoriesMap[category]);
  }, [categoriesMap, category]);

  useEffect(() => {
    console.log("isLoading: ", isLoading);
  }, [isLoading]);

  return (
    <>
      <CategoryTitle>
        <span>{category.toUpperCase()}</span>
      </CategoryTitle>
      {isLoading ? (
        <Spinner />
      ) : (
        <CategoryContainer>
          {products?.length &&
            products.map((product: ProductDto) => (
              <ProductCard key={product.id} product={product} />
            ))}
        </CategoryContainer>
      )}
    </>
  );
};

export default Category;
