import styled from "styled-components";

export const SignInFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;

  @media (max-width: 450px) {
    flex-direction: column;
    grid-row-gap: 10px;
    justify-content: center;
    align-content: center;
  }

  h2 {
    margin: 10px 0;
  }
`;

export const SignInButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 10px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
