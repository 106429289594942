import { CategoriesDto } from "../../dto/Categories.dto";
import { ProductDto } from "../../dto/Product.dto";

import { createSelector } from "reselect";

const selectCategoryReducer = (state: any) => state.categories;

export const selectCategories = createSelector(
  [selectCategoryReducer],
  (categoriesSlice) => categoriesSlice.categories
);

export const selectCategoriesMap = createSelector(
  [selectCategories],
  (categories) =>
    categories.reduce(
      (
        acc: CategoriesDto,
        category: { title: string; items: ProductDto[] }
      ) => {
        const { title, items } = category;
        acc[title.toLowerCase()] = items;
        return acc;
      },
      {}
    )
);

export const selectCategoriesIsLoading = createSelector(
  [selectCategoryReducer],
  (categoriesSlice) => categoriesSlice.isLoading
);
