import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import { AuthenticationContainer } from "./authentication.styles";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAuthLoading,
} from "../../store/user/user.selector";
import Spinner from "../../components/spinner/spinner.component";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);

  const isAuthLoading = useSelector(selectIsAuthLoading);

  useEffect(() => {
    currentUser && navigate("/shop");
  }, [currentUser, navigate]);

  return (
    <AuthenticationContainer>
      {isAuthLoading ? (
        <Spinner />
      ) : (
        <>
          <SignInForm />
          <SignUpForm />
        </>
      )}
    </AuthenticationContainer>
  );
};

export default Authentication;
