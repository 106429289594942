import { createSelector } from "reselect";
import { CartItemDto } from "../../dto/CartItem.dto";

const selectCartReducer = (state: any) => state.cart;

export const selectCartItems = createSelector(
  [selectCartReducer],
  (cart) => cart.cartItems
);

export const selectIsCartOpen = createSelector(
  [selectCartReducer],
  (cart) => cart.isCartOpen
);

export const selectCartCount = createSelector([selectCartItems], (cartItems) =>
  cartItems.reduce(
    (total: number, cartItem: CartItemDto) => total + cartItem.quantity!,
    0
  )
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
  cartItems.reduce(
    (total: number, cartItem: CartItemDto) =>
      total + cartItem.quantity! * cartItem.price!,
    0
  )
);
