import { useState } from "react";

import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";

import FormInput from "../form-input/form-input.component";

import {
  SignInButtonContainer,
  SignInFormContainer,
} from "./sign-in-form.styles";

import { useDispatch } from "react-redux";

import {
  emailSignInStart,
  googleSignInStart,
} from "../../store/user/user.action";

const defaultformFields = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState(defaultformFields);

  const { email, password } = formFields;

  const logGoogleUser = async () => {
    dispatch(googleSignInStart());
  };

  const resetFormFields = () => {
    setFormFields(defaultformFields);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      dispatch(emailSignInStart(email, password));
      resetFormFields();
    } catch (error: any) {
      switch (error.code) {
        case "auth/wrong-password":
          alert("Invalid password for provided Email ID!");
          break;

        case "auth/user-not-found":
          alert("User not found!");
          break;

        default:
          console.log("Error Signing in: ", error.message);
      }
    }
  };

  return (
    <SignInFormContainer>
      <h2>I already have an account</h2>
      <span> Sign in with your email and password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          htmlFor="sign-in-email"
          required
          name="email"
          value={email}
          onChange={handleChange}
          type="email"
          id="sign-in-email"
        />
        <FormInput
          label="Password"
          htmlFor="sign-in-password"
          required
          name="password"
          value={password}
          onChange={handleChange}
          type="password"
          id="sign-in-password"
        />
        <SignInButtonContainer>
          <Button buttonType={BUTTON_TYPE_CLASSES.base} type="submit">
            Sign In
          </Button>
          <Button
            type="button"
            buttonType={BUTTON_TYPE_CLASSES.google}
            onClick={logGoogleUser}
          >
            Sign In With Google
          </Button>
        </SignInButtonContainer>
      </form>
    </SignInFormContainer>
  );
};

export default SignInForm;
