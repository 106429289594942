import { takeLatest, put, call, all } from "typed-redux-saga/macro";

import { USER_ACTION_TYPES } from "./user.types";

import {
  signInSuccess,
  signInFailed,
  signOutFailed,
  signUpSuccess,
  signUpFailed,
  signOutSuccess,
} from "./user.action";

import {
  getCurrentUser,
  createUserDocumentFromAuth,
  signInWithGooglePopup,
  signInUserWithEmailAndPassword,
  createAuthUserWithEmailAndPassword,
  signOutCurrentUser,
} from "../../utils/firebase/firebase";

export function* getSnapshotFromUserAuth(
  userAuth: any,
  additionalDetails?: any
) {
  try {
    const userSnapshot = yield* call(
      createUserDocumentFromAuth,
      userAuth,
      additionalDetails
    );
    yield* put(
      signInSuccess({
        id: userSnapshot.id,
        ...userSnapshot.data(),
      })
    );
  } catch (error) {
    console.log("Error: ", error);
    yield* put(signInFailed(error as Error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield* call(signInWithGooglePopup);
    yield* call(getSnapshotFromUserAuth, user);
  } catch (error) {
    yield* put(signInFailed(error as Error));
  }
}

export function* signInWithEmail({ payload: { email, password } }: any) {
  try {
    const data = yield* call(
      signInUserWithEmailAndPassword,
      email as string,
      password as string
    );
    if (data) {
      const user = data;
      yield* getSnapshotFromUserAuth(user);
    }
  } catch (error) {
    yield* put(signInFailed(error as Error));
    const err = error as any;
    switch (err.code) {
      case "auth/wrong-password":
        alert("Invalid password for provided Email ID!");
        break;

      case "auth/user-not-found":
        alert("User not found!");
        break;

      default:
        console.log("Error Signing in: ", err.message);
    }
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield* call(getCurrentUser);
    if (!userAuth) return;
    yield* call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    yield* put(signInFailed(error as Error));
  }
}

export function* signUp({ payload: { email, password, displayName } }: any) {
  try {
    const userAuth = yield* call(
      createAuthUserWithEmailAndPassword,
      email,
      password
    );
    if (!userAuth) return;
    const { user } = userAuth;
    yield* put(signUpSuccess(user, { displayName }));
  } catch (error) {
    yield* put(signUpFailed(error as Error));
    const err = error as any;
    switch (err.code) {
      default:
        console.log("Error Signing in: ", err.message);
    }
  }
}

export function* signInAfterSignUp({
  payload: { user, additionalDetails },
}: any) {
  yield* call(getSnapshotFromUserAuth, user, additionalDetails);
}

export function* signOut() {
  try {
    yield* call(signOutCurrentUser);
    yield* put(signOutSuccess());
  } catch (error) {
    yield* put(signOutFailed(error as Error));
  }
}

export function* onGoogleSignInStart() {
  yield* takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onCheckUserSession() {
  yield* takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onEmailSignInStart() {
  yield* takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onSignUpStart() {
  yield* takeLatest(USER_ACTION_TYPES.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield* takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onSignOutStart() {
  yield* takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* userSagas() {
  yield* all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignOutStart),
  ]);
}
