import styled from "styled-components";

export const AuthenticationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 3rem;
  width: 900px;
  margin: 30px auto;
  @media (max-width: 1280px) {
    width: 80%;
  }
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 780px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    grid-row-gap: 30px;
  }
  @media (max-width: 380px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    grid-row-gap: 30px;
  }
`;
